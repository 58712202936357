import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import VM from 'scratch-vm';

import styles from './serial-monitor.css';
import {CodeMirror} from 'code-editor';

import {getStageDimensions} from '../../lib/screen-utils.js';
import {STAGE_DISPLAY_SIZES, STAGE_SIZE_MODES} from '../../lib/layout-constants';

import Box from '../box/box.jsx';
import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import DOMElementRenderer from '../../containers/dom-element-renderer.jsx';

import ReactiveButton from 'reactive-button';
import {connect} from 'react-redux';
import {compose} from 'redux';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { getSerialPort } from '../../lib/serial-utils.js';

import { enqueueSnackbar, closeSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import { ClearOutlined, DownOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Dropdown, Space, Popover, Tooltip } from "antd";

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import serialMonitorMessages from './serial-monitor-messages.js';

import clearButtonIcon from './icon--clear-button.svg';

import {
    lockSerial,
    UNLOCK_INDEX,
    FLASH_LOCKED_INDEX,
    MONITOR_LOCKED_INDEX,
} from '../../reducers/web-serial.js';

class StageBoard extends React.Component {
    static get loadingIcon () {
        return <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="spinner"
            className="reactive-btn-loading-svg reactive-spin"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <path
                fill="currentColor"
                d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
            ></path>
        </svg>
    };

    static get SERIAL_CHARACTER_LIMIT() {
        return 10000;
    }

    constructor (props) {
        super(props);
        bindAll(this, [
            'connectToMonitor',
            'disconnectFromMonitor',
            'handleBaudRateChange',
            'handleClearSerialMonitor',
            'handleFlashWithMonitor',
            'handleSendToPort',
            'handleSerialInput',
        ]);

        this.canvas = document.createElement('div');
        this.serial_editor = new CodeMirror(this.canvas, {
            lineNumbers: false,
            autoScrollToEnd: true,
            highlightSyntax: false,
        });

        this.state = {
            buttonState: 'idle',
            baudRate: this.props.vm.getBaudRate(),
            inputText: '',
            showBaudRateWarning: this.props.vm.getBaudRate() === 600,
        };
        
        this.defaultBtnProps = {
            successText: this.props.intl.formatMessage(serialMonitorMessages.connected),
            messageDuration: 1000,
        }

        this.connectBtnProps = {
            idleText: this.props.intl.formatMessage(serialMonitorMessages.connect),
            loadingText: this.props.intl.formatMessage(serialMonitorMessages.connecting),
        }

        this.disconnectBtnProps = {
            idleText: this.props.intl.formatMessage(serialMonitorMessages.disconnect),
            loadingText: this.props.intl.formatMessage(serialMonitorMessages.disconnecting),
        }

        this.buttonProps = this.connectBtnProps;
        this.port = null;
        this.options = {
            dataBits: 8,
            stopBits: 1,
            parity: "none",
            bufferSize: 1024,
            flowControl: "none",
        };
        this.monitorUpdater = null;
        this.portReader = null
        this.encoder = new TextEncoder();

        this.buffer = '';
        this.monitorUpdateDelay = 20;
        
        // React with the real state of the serial connection
        this.onClickHandler = () => {
            if (this.props.webSerialLockState === UNLOCK_INDEX) {
                this.connectToMonitor();
            } else if (this.props.webSerialLockState === MONITOR_LOCKED_INDEX) {
                this.disconnectFromMonitor();
            }
        };

        this.connectionCallback = (state, showSnackBar=true, failMessage='') => {
            if (showSnackBar) {
                if (this.snackbarTimeoutId) {
                    clearTimeout(this.snackbarTimeoutId);
                }
                if (this.snackbarID) {
                    closeSnackbar(this.snackbarID);
                }
            }

            if (state) {
                this.props.lockWebSerial(MONITOR_LOCKED_INDEX, true);
                this.buttonProps = this.disconnectBtnProps;
                if (showSnackBar) {
                    enqueueSnackbar(this.props.intl.formatMessage(serialMonitorMessages.connectedSnackBar), {
                        variant: 'success',
                        autoHideDuration: 2000,
                    });
                }
                this.setState({
                    buttonState: 'success',
                });
            } else {
                this.props.lockWebSerial(UNLOCK_INDEX, true);
                this.buttonProps = this.connectBtnProps;
                if (showSnackBar) {
                    enqueueSnackbar(this.props.intl.formatMessage(serialMonitorMessages.connectionFailedSnackBar), {
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                }
                // TODO: show error message to user in UI

                this.setState({
                    buttonState: 'error',
                });
                this.props.vm.emit('UPDATE_CONSOLE', failMessage);
            }
        };

        this.disconnectionCallback = (disconnected, showSnackBar) => {
            this.props.lockWebSerial(UNLOCK_INDEX, true);
            this.buttonProps = this.connectBtnProps;
            // Avoid state update if the component is unmounted
            if (this._ismounted) {
                this.setState({
                    buttonState: 'idle',
                });
            }
            if (disconnected && showSnackBar) {
                enqueueSnackbar(this.props.intl.formatMessage(serialMonitorMessages.disconnectedSnackBar), {
                    variant: 'success',
                    autoHideDuration: 2000,
                });
            }
        }
    }

    componentDidMount () {
        this.props.vm.addListener('FLASH_WITH_MONITOR', this.handleFlashWithMonitor);
        this.props.vm.addListener('CLEAR_SERIAL_MONITOR', this.handleClearSerialMonitor);

        this._ismounted = true;
    }

    componentWillUnmount () {
        this.props.vm.removeListener('FLASH_WITH_MONITOR', this.handleFlashWithMonitor);
        this.props.vm.removeListener('CLEAR_SERIAL_MONITOR', this.handleClearSerialMonitor);

        // Disconnect from the serial monitor when the component unmounts, e.g. when the user switches to scratch mode
        this.disconnectFromMonitor();
        this._ismounted = false;
    }

    async connectToMonitor (showSnackBar=true) {
        this.props.lockWebSerial(MONITOR_LOCKED_INDEX, false);
        this.setState({
            buttonState: 'loading',
        });

        // Only show the loading alert if the connection takes longer than 500ms
        if (showSnackBar) {
            this.snackbarID = null;
            this.snackbarTimeoutId = setTimeout(() => {
                this.snackbarID = enqueueSnackbar(this.props.intl.formatMessage(serialMonitorMessages.connectingSnackBar), {
                    variant: 'info',
                    iconVariant: {
                        info: <div
                            style={{
                                display: 'flex',
                                marginRight: '10px',
                            }}
                        >
                            <CircularProgress size={20}/>
                        </div>,
                    },
                    autoHideDuration: null,
                });
            }, 500);
        }

        this.port = await getSerialPort((state)=>{
            this.connectionCallback(state, showSnackBar);
        }, this.props.board?.vendorId);

        if (!this.port) {
            this.connectionCallback(false, showSnackBar, 'No Port Found');
            throw new Error('No Port Found');
        }

        await this.port.open({
            baudRate: this.state.baudRate,
            ...this.options,
        })

        // delay the connection callback as the port may not be ready immediately
        setTimeout(() => {
            this.connectionCallback(true, showSnackBar);
        }, 100);

        // Clear the serial monitor when the connection is successful
        if (this.port && this.port.readable) this.handleClearSerialMonitor();

        // release the old monitor updater if it exists
        if (this.monitorUpdater) {
            clearInterval(this.monitorUpdater);
            this.monitorUpdater = null;
        }
        

        this.portReader = await this.port.readable.getReader();
        const releaseLock = () => {
            if(this.monitorUpdater) clearInterval(this.monitorUpdater);
            this.monitorUpdater = null;

            // clear the buffer
            this.buffer = '';

            if (this.portReader) {
                this.portReader.releaseLock();
                this.portReader = undefined;
            }
        }

        // Automatically disconnect from the monitor after a certain amount of time
        // Note: this is a temporary solution to avoid the serial issue with the parallel desktop vm as 
        //       the parallel desktop window vm (ARM) cannot close the port properly after a certain amount 
        //       of time. After that, the device will no longer be able to read any serial data before 
        //       replugging
        // Note: Only applied to 600 baud rate as it is supposed to be used for the parallel desktop
        if (this.state.baudRate == 600) {
            this.monitorAutoCloser = setTimeout(this.disconnectFromMonitor.bind(this), 480000);
        }

        // Use timer to read port and update the serial monitor periodically
        this.monitorUpdater = setInterval(async() => {
            try {
                if (this.port && this.port.readable) {
                    const {value, done} = await this.portReader.read();
                    if (done) {
                        releaseLock();
                    }

                    if (value) {
                        this.buffer += Buffer(value).toString();
                        if (this.buffer.length > StageBoard.SERIAL_CHARACTER_LIMIT) {
                            this.buffer = this.buffer.substring(this.buffer.length - StageBoard.SERIAL_CHARACTER_LIMIT);
                        }
                        this.serial_editor.update(this.buffer); 
                    }
                }
            } catch (e) {
                {
                    releaseLock(); // release the lock if an error occurs
                    await this.disconnectFromMonitor(true); // and disconnect from the monitor
                    console.error(e);
                    await new Promise((resolve) => {
                        if (e instanceof Error) {
                            this.props.vm.emit('UPDATE_CONSOLE', e.message);
                            resolve();
                        }
                    });
                }
            }
        }, this.monitorUpdateDelay);
    }

    async disconnectFromMonitor (showSnackBar=true) {
        // stop the monitor updater
        if (this.monitorUpdater) clearInterval(this.monitorUpdater);
        this.monitorUpdater = null;

        if (this.monitorAutoCloser) clearTimeout(this.monitorAutoCloser);
        this.monitorAutoCloser = null;

        // make a local copy of the port and clear the port variable
        const localPort = this.port;
        this.port = undefined;
        
        try {
            if (this.portReader) await this.portReader.cancel();
            
            if (localPort) {
                localPort.close().then(() => {
                    this.disconnectionCallback(true, showSnackBar);
                });
            } else {
                this.disconnectionCallback(false, showSnackBar);
            }
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                this.props.vm.emit('UPDATE_CONSOLE', e.message);
            }
        }
    }

    handleBaudRateChange ({key}) {
        const value = parseInt(key);

        this.props.vm.setBaudRate(value); // emit the baud rate change event
        this.setState({
            ...this.state,
            baudRate: value,
            showBaudRateWarning: value === 600,
        });
    }

    handleClearSerialMonitor() {
        this.buffer = '';
        this.serial_editor.update('');
    }

    async handleFlashWithMonitor(callback) {
        await this.disconnectFromMonitor(false);
        await callback(null, this.connectToMonitor); // pass connectToMonitor function to resume the serial monitor
    }

    async handleSendToPort(data) {
        if (typeof this.port === 'undefined' || this.port == undefined || this.port == null || this.port.writable == null) {
            console.warn(`Unable to find writable port`);
            return;
        }
    
        const writer = this.port.writable.getWriter();
        await writer.write(this.encoder.encode(data));
        writer.releaseLock();
    }

    handleSerialInput (ev) {
        const key = ev.key;
        if (key === 'Enter') {
            ev.preventDefault();
            this.handleSendToPort(this.state.inputText);
            this.setState({
                ...this.state,
                inputText: '',
            });
        }
    }

    render () {
        const {
            ...props
        } = this.props;

        var stageDimensions = getStageDimensions(this.props.stageSize, false);
        const stageHeight = {
            header: 30,
            input: 40,
            footer: 40,
        }

        const dropdownItems = [
            {
                key: '600',
                label: `600 ${this.props.intl.formatMessage(serialMonitorMessages.baud)}`,
            },
            {
                key: '115200',
                label: `115200 ${this.props.intl.formatMessage(serialMonitorMessages.baud)}`,
            }
        ];

        return (
            <React.Fragment>
                <Box
                    className={classNames(styles.serialMonitorWrapper)}
                    style={{
                        width: stageDimensions.width
                    }}
                >   
                    <Box 
                        className={classNames(styles.serialMonitorTopBar)}
                        style={{
                            height: stageHeight.header,
                            width: stageDimensions.width,
                            position: 'absolute',
                            top: 0,
                        }}
                    >
                        <div className={classNames(styles.row, styles.rowPrimary)}>
                            <div className={classNames(styles.column, styles.headerTitle)}>
                                <FormattedMessage
                                    defaultMessage="Serial Monitor"
                                    description="Serial Monitor info label"
                                    id="gui.serialMonitor.name"
                                />
                            </div>
                        </div>
                    </Box>
                    <Box
                        className={classNames(
                            styles.serialMonitorInputBar,
                            {
                                [styles.disabled]: this.props.inputBarDisabled,
                            }
                        )}
                        style={{
                            height: stageHeight.input,
                            width: stageDimensions.width,
                            position: 'absolute',
                            top: stageHeight.header,
                            
                        }}
                    >
                        <Paper className={classNames(styles.serialMonitorInputBarPaper)}>
                        <InputBase
                                style={{
                                    marginLeft: '0.5rem',
                                    flex: 1,
                                    fontSize: '12px',
                                }}
                                placeholder={this.props.intl.formatMessage(serialMonitorMessages.inputPlaceholder)}
                                inputProps={{ 
                                    'aria-label': 'send characters',
                                    'onKeyPress': this.handleSerialInput,
                                }}
                                value={this.state.inputText}
                                onChange={(ev) => {
                                    this.setState({
                                        ...this.state,
                                        inputText: ev.target.value,
                                    });
                                }}
                                disabled={this.props.inputBarDisabled}
                            />
                            <Divider style={{
                                height: 20,
                                margin: 4,
                            }} orientation="vertical" />
                            <IconButton 
                                color="primary" 
                                style={{
                                    padding: 3,
                                }} 
                                aria-label="directions"
                                onClick={() => {
                                    this.handleSendToPort(this.state.inputText);
                                    this.setState({
                                        ...this.state,
                                        inputText: '',
                                    });
                                }}
                                disabled={this.props.inputBarDisabled}
                            >
                                <KeyboardReturnIcon />
                            </IconButton>
                        </Paper>
                    </Box>
                    <Box
                        className={classNames(styles.serialMonitor)}
                        style={{
                            width: stageDimensions.width,
                            position: 'absolute',
                            top: stageHeight.header + stageHeight.input,
                            bottom: stageHeight.footer,
                        }}
                    >
                        <DOMElementRenderer
                            domElement={this.canvas}
                            style={{
                                width: stageDimensions.width,
                                height: '100%',

                            }}
                            containerStyle={{
                                height: '100%',
                            }}
                        />

                    </Box>
                    <Box 
                        className={classNames(styles.serialMonitorBottomBar)}
                        style={{
                            height: stageHeight.footer,
                            width: stageDimensions.width,
                            position: 'absolute',
                            bottom: 0,
                        }}
                    >
                        <div className={classNames(styles.row, styles.rowEnd)}>
                            <div className={classNames(styles.columnLeft, styles.buttonContainer)}>
                                <img
                                    className={classNames(styles.clearButton)}
                                    draggable={false}
                                    src={clearButtonIcon}
                                    title={this.props.intl.formatMessage(serialMonitorMessages.clearButtonTitle)}
                                    onClick={this.handleClearSerialMonitor}
                                />
                            </div>
                            <div className={classNames(styles.columnRight)}>
                                {this.state.showBaudRateWarning && (
                                    <Popover 
                                        content={this.props.intl.formatMessage(serialMonitorMessages.baudWarningMessage)} 
                                        title={this.props.intl.formatMessage(serialMonitorMessages.baudWarningTitle)}
                                        arrow={{pointAtCenter: true}}
                                        overlayStyle={{
                                            width: "250px",
                                        }}
                                    >
                                        <WarningOutlined style={{ color: '#fcba03' }}/>
                                    </Popover>
                                )}
                            </div>
                            <div className={classNames(styles.columnRight)}>
                                <FormControl className={styles.formControl}>
                                    <ConfigProvider theme={{
                                        token: {
                                            fontSize: 12,
                                            contentFontSizeSM: 12,
                                        },
                                    }}>
                                        <Dropdown menu={{
                                            items: dropdownItems, 
                                            onClick: this.handleBaudRateChange,
                                        }} placement="top" arrow trigger={['click']}>
                                            <Button styles={{backgroundColor: 'black'}} size="small">
                                                {this.state.baudRate} {this.props.stageSizeMode === STAGE_SIZE_MODES.large ? this.props.intl.formatMessage(serialMonitorMessages.baud): "Bd"}
                                                <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </ConfigProvider>
                                </FormControl>
                            </div>
                            <div className={classNames(styles.columnRight)}>
                                <ReactiveButton
                                    buttonState={this.state.buttonState}
                                    idleText={this.buttonProps.idleText}
                                    loadingText={
                                        <React.Fragment>
                                            {StageBoard.loadingIcon} 
                                            {/* {this.buttonProps.loadingText} */}
                                        </React.Fragment>
                                    }
                                    color='blue'
                                    successText={this.defaultBtnProps.successText}
                                    messageDuration={this.defaultBtnProps.messageDuration}
                                    onClick={this.onClickHandler}
                                    size="small"
                                    rounded
                                    disabled={this.props.buttonDisabled}
                                    style={{
                                        borderRadius: '13px'
                                    }}
                                />
                            </div>
                        </div>
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

StageBoard.propTypes = {
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)).isRequired,
    webSerialLockState: PropTypes.number.isRequired,
    webSerialReady: PropTypes.bool.isRequired,
    buttonDisabled: PropTypes.bool.isRequired,
    changeBaudRateDisabled: PropTypes.bool.isRequired,
    inputBarDisabled: PropTypes.bool.isRequired,
    vm: PropTypes.instanceOf(VM),
    intl: intlShape,
    board: PropTypes.object,
};

const mapStateToProps = state => ({
    webSerialLockState: state.scratchGui.webSerial.locked,
    webSerialReady: state.scratchGui.webSerial.ready,
    buttonDisabled: state.scratchGui.webSerial.locked === FLASH_LOCKED_INDEX,
    changeBaudRateDisabled: state.scratchGui.webSerial.locked === MONITOR_LOCKED_INDEX,
    inputBarDisabled: state.scratchGui.webSerial.locked === FLASH_LOCKED_INDEX || !state.scratchGui.webSerial.ready,
    board: state.scratchGui.board,
    stageSizeMode: state.scratchGui.stageSize.stageSize,
});

const mapDispatchToProps = dispatch => ({
    lockWebSerial: (lock_index, ready) => dispatch(lockSerial(lock_index, ready)),
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(StageBoard);