import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {
    defaultProjectId,
    setDefaultProjectId
} from '../reducers/project-state';

/**
 * Scratch Mode component passes setScratchModeOn, setScratchModeOff function to its child.
 * It also includes `scratchMode` in the props passed to the children.
 * It expects this child to be a function with the signature
 *     function (setScratchModeOn, setScratchModeOff, {scratchMode, ...props}) {}
 * The component can then be used to attach scratch mode setting functionality
 * to any other component:
 *
 * <scratchMode>{(setScratchModeOn, setScratchModeOff, props) => (
 *     <MyCoolComponent
 *         scratchEnabled={props.scratchMode}
 *         onClick={setScratchModeOn}
 *         {...props}
 *     />
 * )}</scratchMode>
 */
class ScratchMode extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'setScratchModeOn',
            'setScratchModeOff'
        ]);
    }
    setScratchModeOn () {
        this.props.vm.setScratchMode(true);
    }
    setScratchModeOff () {
        this.props.vm.setScratchMode(false);
    }
    componentDidMount () {
        this.props.scratchMode ? this.props.setDefaultProjectId(defaultProjectId.SCRATCH) : this.props.setDefaultProjectId(defaultProjectId.BOARD);
    }
    componentDidUpdate (prevProps) {
        if (prevProps.scratchMode !== this.props.scratchMode) {
            this.props.scratchMode ? this.props.setDefaultProjectId(defaultProjectId.SCRATCH) : this.props.setDefaultProjectId(defaultProjectId.BOARD);
        }
    }
    render () {
        const {
            /* eslint-disable no-unused-vars */
            children,
            scratchMode,
            vm,
            setDefaultProjectId,
            /* eslint-enable no-unused-vars */
            ...props
        } = this.props;

        return this.props.children(this.setScratchModeOn, this.setScratchModeOff, props);
    }
}

ScratchMode.propTypes = {
    children: PropTypes.func,
    scratchMode: PropTypes.bool,
    vm: PropTypes.shape({
        setScratchMode: PropTypes.func
    }),
};

const mapStateToProps = state => ({
    vm: state.scratchGui.vm,
    scratchMode: state.scratchGui.vmStatus.scratch_mode,

});

const mapDispatchToProps = dispatch => ({
    setDefaultProjectId: (id) => dispatch(setDefaultProjectId(id))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ScratchMode);