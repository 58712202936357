exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.watermark_sprite-image_3ywoR {\n    margin: auto;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    max-width: 72px;\n    max-height: 72px;\n}\n", ""]);

// exports
exports.locals = {
	"sprite-image": "watermark_sprite-image_3ywoR",
	"spriteImage": "watermark_sprite-image_3ywoR"
};