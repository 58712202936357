import bindAll from 'lodash.bindall';
import omit from 'lodash.omit';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import ThrottledPropertyHOC from '../lib/throttled-property-hoc.jsx';

import VM from 'scratch-vm';
import storage from '../lib/storage';
import getCostumeUrl from '../lib/get-costume-url';

import WatermarkComponent from '../components/watermark/watermark.jsx';

import UNOIcon from '../components/board-modal/img--uno.png';
import NANOIcon from '../components/board-modal/img--nano.png';

import {BOARD_ARDUINO_UNO, BOARD_ARDUINO_NANO} from '../reducers/boards';
import {openBoardModal} from '../reducers/modals';

class Watermark extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'getBoardIcon',
            'getCostumeData'
        ]);
    }

    getBoardIcon () {
        switch (this.props.board.name) {
            case BOARD_ARDUINO_UNO.name:
                return UNOIcon;
            case BOARD_ARDUINO_NANO.name:
                return NANOIcon;
            default:
                return null;
        }
    }

    getCostumeData () {
        if (!this.props.asset) return null;

        return getCostumeUrl(this.props.asset);
    }

    render () {
        const componentProps = omit(this.props, ['asset', 'vm']);
        return (
            <div onClick={this.props.scratchMode ? null : this.props.onOpenBoardModal}>
                <WatermarkComponent
                    costumeURL={this.props.scratchMode ? this.getCostumeData() : this.getBoardIcon()}
                    {...componentProps}
                /> 
            </div>
        );
    }
}

Watermark.propTypes = {
    asset: PropTypes.instanceOf(storage.Asset),
    vm: PropTypes.instanceOf(VM).isRequired,
    scratchMode: PropTypes.bool,
    board: PropTypes.object,
    onOpenBoardModal: PropTypes.func,
};

const mapStateToProps = state => {
    const targets = state.scratchGui.targets;
    const currentTargetId = targets.editingTarget;

    let asset;
    if (currentTargetId) {
        if (targets.stage.id === currentTargetId) {
            asset = targets.stage.costume.asset;
        } else if (targets.sprites.hasOwnProperty(currentTargetId)) {
            const currentSprite = targets.sprites[currentTargetId];
            asset = currentSprite.costume.asset;
        }
    }

    return {
        vm: state.scratchGui.vm,
        asset: asset,
        scratchMode: state.scratchGui.vmStatus.scratch_mode,
        board: state.scratchGui.board,
    };
};

const mapDispatchToProps = dispatch => ({
    onOpenBoardModal: () => dispatch(openBoardModal()),
});

const ConnectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ThrottledPropertyHOC('asset', 500)(Watermark)
);

export default ConnectedComponent;
