import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import {injectIntl} from 'react-intl';
import VM from 'scratch-vm';

import {CodeMirror} from 'code-editor';
import AssetPanel from '../components/asset-panel/asset-panel.jsx';
import DOMElementRenderer from './dom-element-renderer.jsx';

import errorBoundaryHOC from '../lib/error-boundary-hoc.jsx';

import {connect} from 'react-redux';

import {
    setEditor,
} from '../reducers/editor-tab';

class EditorTab extends React.Component {
    static get localStorageCodeID () {
        return 'editor-code';
    }

    constructor (props) {
        super(props);
        bindAll(this, [
            'handleResetCodeEditor',
            'handleUpdateCodeEditor',
            'handleFormatCodeEditor',
            'handleUndoCodeEditor',
            'loadCodeFromLocalStorage',
        ]);

        this.canvas = document.createElement('div');
        this.code_editor = new CodeMirror(this.canvas, {
            readOnly: false,
        });
        this.props.setCodeEditor(this.code_editor);

        this.state = {
            ref: null,
            width: 0,
            height: 0,
        }

        // Use a ResizeObserver to measure the size of the node
        this.resizeObserver = new ResizeObserver((e) => {
            const node = e[0].target;
            if (node === null || node.offsetWidth === 0 || node.offsetHeight === 0) {
                return;
            }
            this.setState({ 
                ref: node,
                width: node.offsetWidth,
                height: node.offsetHeight
            });
        });

        this.onRefChange = node => {
            if (node === null) {
                return;
            }
            if (this.node) {
                this.resizeObserver.unobserve(this.node);
            }
            this.node = node;
            this.resizeObserver.observe(this.node);
        };

        this.loadCodeFromLocalStorage();
    }

    componentDidMount () {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            localStorage.setItem(EditorTab.localStorageCodeID, this.code_editor.getText());
        });

        this.props.vm.addListener('RESET_CODE_EDITOR', this.handleResetCodeEditor);
        this.props.vm.addListener('UPDATE_CODE_EDITOR', this.handleUpdateCodeEditor);
        this.props.vm.addListener('FORMAT_CODE_EDITOR', this.handleFormatCodeEditor);
        this.props.vm.addListener('UNDO_CODE_EDITOR', this.handleUndoCodeEditor);
    }

    componentWillUnmount () {
        if (this.node) {
            this.resizeObserver.unobserve(this.node);
        }

        this.props.vm.removeListener('RESET_CODE_EDITOR', this.handleResetCodeEditor);
        this.props.vm.removeListener('UPDATE_CODE_EDITOR', this.handleUpdateCodeEditor);
        this.props.vm.removeListener('FORMAT_CODE_EDITOR', this.handleFormatCodeEditor);
        this.props.vm.removeListener('UNDO_CODE_EDITOR', this.handleUndoCodeEditor);
    }

    handleResetCodeEditor() {
        this.code_editor.resetToArduino();
    }

    handleUpdateCodeEditor(code) {
        this.code_editor.update(code);
    }

    handleFormatCodeEditor() {
        this.code_editor.format();
    }

    handleUndoCodeEditor(redo) {
        if (redo) {
            this.code_editor.redo();
        } else {
            this.code_editor.undo();
        }
    }

    loadCodeFromLocalStorage () {
        const code = localStorage.getItem(EditorTab.localStorageCodeID);
        if (code === null) {
            return;
        }
        this.code_editor.update(code);
    }

    render () {
        return (
            <AssetPanel
                ref={this.onRefChange}
                isSelector={false}
            >
                <DOMElementRenderer
                    domElement={this.canvas}
                    style={{
                        // 2 is the border width of the asset panel 
                        // 0.5 is the offset so that the resizeObserver is based on the asset panel not the canvas
                        height: this.state.height - 2 - 0.51,
                        width: this.state.width - 2 - 0.51,
                        overflow: 'hidden',
                        borderTopRightRadius: '0.5rem',
                        borderBottomRightRadius: '0.5rem',
                        position: 'absolute',
                        // 1.5 = 1 side of border width + 0.5 offset
                        right: '1.5px',
                        bottom: '1.5px',
                    }}
                />

            </AssetPanel>
        )
    }
}

EditorTab.propTypes = {
    vm: PropTypes.instanceOf(VM).isRequired,
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    setCodeEditor: (editor) => dispatch(setEditor(editor)),
});


export default errorBoundaryHOC('Editor Tab')(
    injectIntl(connect(
        mapStateToProps,
        mapDispatchToProps
    )(EditorTab))
);